import { PageProps } from 'gatsby'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import React, { useEffect, useState } from 'react'
import { getSubmissionLectures, getLecture } from '../../../../../API/getLecture'
const ReactQuill = typeof window !== 'undefined' ? require('react-quill') : () => <></>

import 'quill/dist/quill.snow.css'
import 'styles/index.scss'
import { Col } from 'react-bootstrap'
// import { getLecture } from '../../../../../API/getLecture'
import axios from 'axios'
import { getUrl } from '../../../../../API/getUrl'
import { Icon } from '@iconify/react'
import formatDuration from 'format-duration'
import { NonUndefined, PromiseType } from 'utility-types'

export type LinkType = {
	link: string
	name: string
	description: string
	doc: boolean
}

const ResourceView: React.FC<PageProps> = ({ params: { id, lecture_id } }) => {
	const { ShowDetails, viewAs } = useGetCourseDetails(id)
	const [asset, setAsset] = useState<LinkType>()
	const [recordings, setRecordings] = useState<any[]>([])
	const [participants, setParticipants] = useState<any[]>([])
	const [assetSubmission, setAssetSubmission] = useState<NonUndefined<PromiseType<NonUndefined<ReturnType<typeof getSubmissionLectures>>>>>([])
	useEffect(() => {
		const run = async () => {
			const asset = await getLecture(lecture_id, viewAs)
			// console.log({ asset })
			try {
				const recordings = (await axios.post(getUrl('asset/webex/get_recordings'), { lecture_id }))?.data?.items
				if (recordings) {
					setRecordings(recordings)
				}
			} catch (err) {
				console.error(err)
			}

			try {
				const participants = (await axios.post(getUrl('asset/webex/get_participants'), { lecture_id }))?.data?.items
				if (participants) {
					setParticipants(participants)
				}
			} catch (err) {
				console.error(err)
			}
			if (asset) {
				setAsset(asset)
			}
		}
		run()
	}, [lecture_id, viewAs])

	useEffect(() => {
		const run = async () => {
			if (viewAs === 'Teacher') {
				const submission = await getSubmissionLectures(lecture_id, id)
				if (submission) {
					setAssetSubmission(submission)
					console.log(submission)
				}
			}
		}
		run()
	}, [lecture_id, viewAs])

	return (
		<>
			<Col>
				{ShowDetails}
				{asset && (
					<>
						<h2 className='pt-2'>{asset.name}</h2>
						Start : {new Date((asset as any).available_from).toLocaleString()}
						<br />
						Duration: {(asset as any).duration} Mins
						<div className='modalclass'>
							<div className='card'>
								<div className='row'>
									<Col md='8'>
										<ReactQuill
											theme='snow'
											value={asset.description}
											readOnly={true}
											modules={{
												toolbar: false,
											}}
										/>
									</Col>
								</div>
							</div>
						</div>
						{asset.link && (
							<>
								<a className='btn btn-primary' href={asset.link} target='_blank' rel='noopener noreferrer'>
									Join
								</a>
							</>
						)}
						<hr />
						<h2>Recordings:</h2>
						<ul style={{ listStyleType: 'none' }}>
							{recordings.map((elem, idx) => {
								return (
									<>
										<li
											style={{ cursor: 'pointer' }}
											onClick={() => {
												window?.open(elem?.playbackUrl)
											}}
										>
											<Icon height={30} icon={`bx:bx-play-circle`}></Icon>
											{`Play Recording ${idx + 1}`}
										</li>
									</>
								)
							})}
						</ul>
						<hr />
						<table className='table'>
							<thead>
								<tr>
									<th scope='col'>Name</th>
									<th scope='col'>Joined Time</th>
									<th scope='col'>Left Time</th>
									<th scope='col'>Duration</th>
								</tr>
							</thead>
							<tbody>
								{participants.map((elem, idx) => {
									return (
										<>
											<tr
												key={idx}
												// onClick={() => {
												// 	window?.open(elem?.playbackUrl)
												// }}
											>
												<td>{`${elem.displayName}`}</td>
												<td>{`${new Date(elem.joinedTime).toLocaleString()}`}</td>
												<td>{`${new Date(elem.leftTime).toLocaleString()}`}</td>
												<td>{`${formatDuration(
													new Date(elem.leftTime).getTime() - new Date(elem.joinedTime).getTime()
												)}`}</td>
											</tr>
										</>
									)
								})}
							</tbody>
						</table>
					</>
				)}
				{asset && viewAs === 'Teacher' && (
					<div>
						<div className='row mt-5'>
							<div className='col-6'>
								<span>
									<h4>Viewed Student</h4>
								</span>
								<table className='table'>
									<thead>
										<tr>
											<th scope='col'>Index</th>
											<th scope='col'>Name</th>
											<th scope='col'>UserName</th>
											<th scope='col'>Time</th>
										</tr>
									</thead>
									<tbody>
										{assetSubmission
											.filter((assetSubmission) => assetSubmission.isOpened === true)
											.map((elem, index) => {
												return (
													<>
														<tr>
															<th scope='row'>{index + 1}</th>
															<td>
																{elem.first_name} {elem.last_name}
															</td>
															<td>{elem.username}</td>
															<td>{new Date(elem.last_open_date).toLocaleString()}</td>
														</tr>
													</>
												)
											})}
									</tbody>
								</table>
							</div>
							<div className='col-6'>
								<span>
									<h4>Not Viewed</h4>
								</span>
								<table className='table'>
									<thead>
										<tr>
											<th scope='col'>Index</th>
											<th scope='col'>Name</th>
											<th scope='col'>Username</th>
										</tr>
									</thead>
									{assetSubmission
										.filter((assetSubmission) => assetSubmission.isOpened == false)
										.map((elem, index) => {
											return (
												<>
													<tr>
														<th scope='row'>{index + 1}</th>
														<td>
															{elem.first_name} {elem.last_name}
														</td>
														<td>{elem.username}</td>
													</tr>
												</>
											)
										})}
								</table>
							</div>
						</div>
					</div>
				)}
			</Col>
		</>
	)
}

export default ResourceView
